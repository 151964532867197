.root {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #bcccdc;
  z-index: 100;
}

.left {
  flex-grow: 1;
  flex-basis: 1px; // same basis value for left and right containers
  display: flex;
  align-items: center;

  .logo {
    height: 45px;
    width: 300px;
    margin: 0 24px;
    cursor: pointer;
  }

  .logoIcon {
    display: none;
    height: 36px;
    width: 40px;
    margin: 0 15px;
    cursor: pointer;
  }
}

.middle {
  position: relative;

  .search {
    width: 400px;
    margin: 8px 0;
    padding: 0.3em 0.3em;
    background-color: #f0f4f8;
    color: #243b53;
    border: solid 0px #cbd2d9;
    border-radius: 11px;
    box-shadow: inset 0 0 2px #bcccdc;
    outline: none;
    font-family: "Source Code Pro", monospace;
    font-size: 24px;
    font-weight: 700;

    &::placeholder {
      color: #829ab1;
      opacity: 1;
    }
  }

  .clearSearchButton {
    position: absolute;
    right: 15px;
    top: 18px;

    height: 26px;
    color: #829ab1;
    cursor: pointer;

    display: none;

    &.tutorialActive {
      display: inherit !important;
      color: #102a43;
      z-index: 201;
    }
  }

  &:hover {
    .clearSearchButton {
      display: inherit;
    }
  }
}

.right {
  flex-grow: 1;
  flex-basis: 1px; // same basis value for left and right containers
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .favorites {
    color: #f0b429;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    cursor: pointer;
    margin: 0 22px;

    .star {
      height: 27px;
      position: relative;
      overflow: visible;

      path {
        stroke: #243b53 !important;
        stroke-width: 32px !important;
      }
    }

    .text {
      font-size: 31px;
      font-weight: 900;
      margin-left: 10px;
      user-select: none;
      min-width: 0.6em;

      font-family: "Source Code Pro", monospace;
      -webkit-text-stroke: 1.75px #243b53;
      text-stroke: 1.75px #243b53;
    }
  }
}

.openFavoritesWindow {
  display: initial;
}

.closedFavoritesWindow {
  display: none !important;
}

.tutorialBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
  background-color: #d9e2ec;
  opacity: 0.5;
}

@media screen and (max-width: 1000px) {
  .logo {
    height: 30px !important;
    width: 206px !important;
  }
  .search {
    width: 350px !important;
  }
}

@media screen and (max-width: 850px) {
  .logo {
    display: none;
  }
  .logoIcon {
    display: block !important;
    padding-left: 5px;
  }
  .search {
    margin: 0;
    width: 270px !important;
  }

  .star {
    height: 25px !important;
    position: relative !important;
    top: 0 !important;
  }

  .text {
    margin-left: 6px !important;
  }
}

@media screen and (max-width: 450px) {
  .search {
    width: calc(100vw - 170px) !important;
  }

  .favorites {
    margin: 0 15px 0 10px !important;
  }
}

@media screen and (min-width: 1500px) {
  .logo {
    height: 45px !important;
  }
}
