.favoritesWindow {
  position: fixed;
  top: 67px;
  right: 20px;
  z-index: 101;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 5px #bcccdc;
  padding: 25px;
  padding-top: 20px;

  .title {
    font-size: 25px;
    font-weight: 700;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .table {
    display: grid;
    grid-template-columns: 1fr 0fr 0fr;
    grid-gap: 10px 25px;
    text-align: left;
    align-items: center;

    .headerCell {
      font-size: 20px;
      font-family: "Barlow", sans-serif;
      color: #9aa5b1;
      text-align: left;
      display: none;
    }

    .domain {
      width: auto !important;
      min-width: 200px;
      height: min-content;
      margin: 0;
    }

    .cost {
      font-size: 30px;
      font-weight: 800;
      text-align: right;
      font-family: "Source Code Pro", sans-serif;

      &.register {
        color: #42e360;

        &.premium {
          color: #f0b429;
        }
      }

      &.renew {
        color: #829ab1;
        text-decoration: line-through;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .favoritesWindow {
    .table {
      grid-template-columns: 1fr 0fr;
      .renew {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .domain {
    min-width: 150px !important;
    max-width: 100px;
  }

  .favoritesWindow {
    // full width
    top: 67px;
    left: 5px;
    right: 5px;
  }
}

@media screen and (max-width: 1000px) {
  .favoritesWindow {
    padding: 18px;

    .title {
      font-size: 20px;
    }

    .table {
      grid-gap: 10px 13px;

      .headerCell {
        font-size: 17px;
      }

      .cost {
        font-size: 23px;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .favoritesWindow {
    top: 68px;
    right: 21px;
  }
}
