.root {
  position: relative;
}

.description {
  display: flex;
  justify-content: center;
}

.chart {
  position: absolute;
  margin-top: -20px; // slightly offset into title bar
  height: calc(100% + 20px);
  z-index: -1;

  display: flex;
  justify-content: center;

  .chartPlaceHolder {
    background: #d9e2ec;
  }
}

.domainCount {
  position: relative;

  display: flex;
  justify-content: center;

  .text {
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 20px;

    .domainAdditions {
      position: absolute;
      font-size: 15px;
      padding-left: 90px;
      color: #42e360;
    }

    .domainRemovals {
      position: absolute;
      font-size: 15px;
      padding-left: 90px;
      color: red;
    }
  }
}

.bottomContent {
  display: flex;
}

.popularDomains {
  width: 100%;
  padding: 15px 20px;
  position: relative;

  &.notFetchedYet {
    min-height: 200px !important;
  }

  .table {
    display: grid;
    grid-template-columns: 0fr 1fr 0fr;
    grid-gap: 12px 16px;

    .icon {
      width: 25px;
    }

    .domain {
      font-size: 20px;
      font-weight: 700;
      overflow: hidden;
    }

    .pageHits {
      font-weight: 800;
      font-size: 25px;
      text-align: right;
    }
  }
}

@media screen and (max-width: 1000px) {
  .domainCount {
    .text {
      font-size: 23px;
      margin-top: 0px;
      margin-bottom: 15px;

      .domainAdditions {
        font-size: 13px;
      }
      .domainRemovals {
        font-size: 13px;
      }
    }
  }

  .popularDomains {
    min-width: 230px;
    padding: 15px;

    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .table {
      grid-gap: 10px 15px;

      .icon {
        width: 20px;
      }

      .domain {
        font-size: 17px;
      }

      .pageHits {
        font-size: 20px;
      }
    }
  }
}
