.info {
  margin: 20vh auto 0 auto;
  width: 600px;
  position: relative;

  .logo {
    position: relative;

    svg {
      width: 100%;
      height: 121px;
    }
  }

  .subtext {
    position: absolute;
    right: -100px;
    margin-top: 8px;
    margin-left: 200px;
    font-size: 25px;
    // word-spacing: -0.2em;

    font-weight: 800;
    color: #243b53;

    .changelogDot {
      position: absolute;
      // use opposite offset to grow size (when updates) in opposite direction
      left: calc(100% + 0.3em);
      bottom: calc(100% - 0.3em);
    }
  }
}

.search {
  position: relative;
  margin: 160px auto 0 auto;
  width: 400px;

  .input {
    width: calc(100% - 0.6em);
    padding: 0.3em 0.3em;
    background-color: #fff;
    color: #243b53;
    border: solid 0px #cbd2d9;
    border-radius: 11px;
    font-size: 25px;
    font-weight: 700;
    box-shadow: 1px 1px 1px 1px #bcccdc;
    outline: none;
    font-family: "Source Code Pro", sans-serif;
    padding-left: 0.5em;

    &::placeholder {
      color: #9fb3c8;
      opacity: 1;
    }
  }

  .exploreButton {
    position: absolute;
    left: calc(100% + 10px);
    top: 10px;
    margin-left: 10px;
    padding: 0 0.1em;
    line-height: 100%;

    // background-color: #42e360;
    // border-radius: 11px;
    // box-shadow: 0px 0.5px 2px #d9e2ec;
    font-size: 25px;
    font-weight: 800;
    color: #42e360;
    text-shadow: 0 0 2px #d9e2ec;
    // font-family: "Source Code Pro", sans-serif;

    display: flex;
    align-items: center;
    white-space: nowrap;

    .exploreText {
      cursor: pointer;
    }

    .orText {
      display: none;
    }

    .arrow {
      z-index: 200;
      position: absolute;
      right: 70px;
      top: calc(100% + 20px);
      height: 70px;
      width: auto;

      color: #829ab1;
    }

    .arrowText {
      position: absolute;
      top: 100px;
      right: 150px;

      font-size: 22px;
      font-weight: 700;
      color: #829ab1;
      text-shadow: none;
    }
  }
}

.bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;

  .about {
    margin: 12px auto;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #829ab1;
    cursor: pointer;
  }
}

.below {
  position: absolute;
  top: 100vh;
  width: 100%;
}

@media screen and (min-width: 1500px) {
  .info {
    width: 700px;

    .logo {
      svg {
        width: 700px;
        height: 143px;
      }
    }

    .subtext {
      margin-top: 15px;
      margin-left: 200px;
    }
  }
}

@media screen and (max-width: 850px) {
  .info {
    width: 80vw !important;
  }

  .logo {
    svg {
      width: 80vw !important;
      height: 20vw !important;
    }
  }

  .subtext {
    right: -11px !important;
    font-size: 16px !important;
    margin-top: 0px !important;
    margin-left: auto !important;
  }

  .about {
    font-size: 20px !important;
  }

  .search {
    margin-top: 140px !important;
    width: 80% !important;
    max-width: 400px;

    .exploreButton {
      position: inherit;
      left: auto;
      padding: 0;

      display: flex;
      flex-direction: column;

      span {
        margin: 12px auto;
        font-size: 22px;
      }

      .arrow {
        display: none;
      }

      .arrowText {
        display: none;
      }

      .orText {
        display: initial;
        color: #829ab1;
        font-size: 20px !important;
        margin: 5px 0;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .info {
    margin-top: 30% !important;
    width: 90% !important;
  }

  .logo {
    svg {
      width: 100% !important;
      height: 17vw;
    }
  }

  .subtext {
    margin-left: 0 !important;
    right: auto !important; // leave space for changelog dot
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;

    font-size: 14px !important;

    div {
      position: relative;

      .changelogDot {
        left: calc(100% + 0.2em) !important;
        bottom: calc(100% - 0.6em) !important;
      }
    }
  }

  .search {
    margin-top: 80px !important;
    width: 90% !important;

    .input {
      font-size: 20px;
    }

    .exploreButton {
      font-size: 20px;
    }
  }
}
