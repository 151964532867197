.root {
  margin: 0 10px;
  // background-color: #42e360;
  // border-radius: 11px;
  // box-shadow: 0 0 2px #d9e2ec;

  font-family: "Source Code Pro", monospace;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  word-spacing: 0em;

  .current {
    min-width: 2em;
    text-align: right;
  }

  .separation {
    margin: 0 0.5em;
  }
}

@media screen and (max-width: 600px) {
  .root {
    display: none;
  }
}
