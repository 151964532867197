.favoritesTutorial {
  display: none;
  width: 0;
  font-style: normal;
  cursor: initial;

  &.active {
    display: initial;
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 12000px; // to be set dynamically to page height after scroll
    z-index: -1;
    background-color: #d9e2ec;
    opacity: 0.5;
  }

  .text {
    z-index: 200;
    position: absolute;
    left: 80px;
    top: -70px;
    width: 400px;

    font-family: "Raleway", sans-serif;
    font-weight: 800;
    color: #102a43;
    font-size: 34px;
    text-shadow: 0px 0px 6px #9fb3c8;

    .mobileTextBreak {
      display: none;
    }
  }

  .arrow {
    z-index: 200;
    position: absolute;
    left: calc(100% + 10px);
    bottom: 6px;
    height: 50px;
    width: auto;
  }

  &.cardToLeft {
    .text {
      left: auto;
      right: 0;
    }

    .arrow {
      transform: scale(-1, 1);
      left: auto;
      right: calc(100%);
    }
  }
}

.mobileArrow {
  display: none;
}

.step2 {
  .arrow {
    top: auto;
    bottom: -10px;
    left: auto;
    right: 0px;
  }

  .text {
    top: 150px;
    left: auto;
    right: -20px;
    width: 60vw;
  }

  &.cardToLeft {
    .arrow {
      left: auto;
      right: calc(100%) !important;
    }
  }
}

@media screen and (max-width: 850px) {
  .favoritesTutorial {
    .text {
      position: absolute;
      left: -100%;
      right: 0px;
      top: -85px;
      font-size: 25px;

      .mobileTextBreak {
        display: initial;
      }
    }

    .arrow {
      display: none;
    }

    .mobileArrow {
      display: initial;
      z-index: 200;
      position: absolute;
      left: calc(100% + 8px);
      bottom: 5px;
      height: 50px;
      width: auto;
    }

    &.cardToLeft {
      .text {
        left: auto;
        right: -80px;
      }

      .mobileArrow {
        transform: scale(-1, 1);
        left: auto;
        right: calc(100% + 5px);
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .favoritesTutorial {
    .text {
      font-size: 23px;
    }
  }
}
