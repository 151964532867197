.container {
  background-color: #42e360;
  width: 96px;
  margin: 12px;
  border-radius: 8px;
  font-weight: 700;
  padding: 4px 12px;
  padding-right: 6px;
  box-shadow: 0px 0px 3px #d9e2ec;
  color: #243b53;
  overflow: hidden;
  position: relative;
  overflow: visible;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-family: "Source Code Pro", sans-serif;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  user-select: none;

  font-size: 16px;
}

.longName {
  font-size: 13px;
  margin-top: 3px;
}

.veryLongName {
  font-size: 9px;
  margin-top: 6px;
}

.icons {
  position: absolute;
  right: -6px;
  top: -15px;
  font-size: 26px;
  display: flex;

  .star {
    position: relative;
    top: 6px;

    width: 28px;
    color: #f0b429;
    filter: drop-shadow(0px 0px 2px #486581);
    overflow: visible;

    path {
      stroke: #243b53 !important;
      stroke-width: 32px !important;
    }
  }

  .emoji {
    font-weight: 900;
    text-shadow: 0px 0px 3px #486581;

    margin-left: 5px;
  }

  .salePrice {
    color: #f0b429;
    font-weight: 800;
    -webkit-text-stroke: 1.5px #243b53;
    text-stroke: 1.5px #243b53;
    text-shadow: 0px 0px 3px #486581;

    margin-left: 5px;
  }
}

.taken {
  background-color: transparent;
  color: #829ab1;
  box-shadow: none;
}

.preciseQueried {
  border: 3px solid #243b53;
  margin: 9px; // compensate larger border
  // box-shadow: 0 0 3px #616e7c;
}

.hasSale:not(.taken) {
  // background-color: #fadb5f;
}

.container > i {
  float: right;
  color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}
.container:hover > i {
  visibility: visible !important;
}

a {
  outline: 0;
}

.icon {
  width: 16px;
  vertical-align: -3px;
  margin-left: 6px;
  color: #3e4c59;
}

@media screen and (max-width: 700px) {
  .container {
    width: 68px;
    margin: 6px;
    padding: 4px 6px;

    // .star {
    //   display: none;
    // }

    .title {
      font-size: 14.5px;
    }

    .longName {
      font-size: 9px;
      margin-top: 5px;
    }

    .veryLongName {
      font-size: 9px;
      margin-top: 5px;
    }
  }

  .icons {
    top: -8px;
    font-size: 20px;

    .star {
      top: 2px;
      width: 22px;
      color: #f0b429;
    }

    .emoji {
      font-size: 18px;

      margin-left: 2px;
    }

    .salePrice {
      -webkit-text-stroke: 1.25px #243b53;
      text-stroke: 1.25px #243b53;

      margin-left: 2px;
    }
  }

  .preciseQueried {
    border: 3px solid #243b53;
    margin: 3px !important; // compensate larger border
  }
}

@media screen and (min-width: 1300px) {
  .container {
    width: 120px;

    .title {
      font-size: 20px;
    }

    .longName {
      font-size: 15.5px;
      margin-top: 3.5px;
    }

    .veryLongName {
      font-size: 11px;
      margin-top: 7px;
    }
  }
}
