body {
  margin: 0;
  padding: 0;

  font-family: "Raleway", sans-serif;

  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f0f4f8;
  color: #243b53;
  scroll-behavior: smooth;
}

div > div.changelog-anchor.cleanslate {
  top: 0 !important;
  display: block !important;
  /* width: 11px !important;
  height: 11px !important; */
  background-color: #243b53 !important;
}

@media screen and (max-width: 850px) {
  div > div.changelog-anchor.cleanslate {
    width: 8px !important;
    height: 8px !important;
  }
}
