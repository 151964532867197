.card {
  position: absolute;
  top: calc(100% + 1px);
  left: -2px;
  z-index: 20;
  border-radius: 12px;
  background: #fff;
  border: solid 3px transparent;
  box-shadow: 0 0 5px #bcccdc;
  cursor: default;
  font-family: "Raleway", sans-serif;

  border-radius: 12px;

  &.cardToLeft {
    left: auto;
    right: 2px;
  }

  &.isFavorite {
    border: solid 3px #f0b429;
    // box-shadow: 0 0 10px 1px #f0b429;
  }

  &.extensionInfoActive {
    border: none; // to allow the extension content to paint all card background
    box-shadow: 0 0 5px #9fb3c8; // card background is grey, use darker color for shadow

    overflow: hidden;
  }
}

.titleBar {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  padding: 12px calc(12px + 38px);
  position: relative;
  z-index: 100;

  // &.isTldInfoCard {
  //   border-bottom: 2px solid #d9e2ec;

  //   &:not(.isFavorite) {
  //     margin: 0 -3px; // compensate for transparent border
  //   }
  // }

  .text {
    white-space: nowrap;
    font-family: "Source Code Pro", sans-serif;

    &.available {
      color: #243b53;
      position: relative;
    }

    .extension {
      cursor: pointer;
      user-select: none;
      font-style: italic;
      text-shadow: 0 0 2px #bcccdc;
      position: relative;

      &.extensionInfoTutorialActive {
        // show above greyed out background
        position: relative;
        z-index: 201;
      }
    }
  }

  .starContainer {
    position: absolute;
    right: -42px;
    top: 2px;

    .star {
      width: 1.2em;
      color: #f0b429;
      cursor: pointer;
      overflow: visible;

      &.isFavorite {
        width: 30px;

        path {
          stroke: #243b53 !important;
          stroke-width: 32px !important;
        }
      }

      &.favoritesTutorialActive {
        // show above greyed out background
        position: relative;
        z-index: 201;
      }
    }
  }

  .spinner {
    // corner positioning
    // position: absolute;
    // right: 12px;

    position: absolute;
    right: -38px;
  }

  .infoIcon {
    position: absolute;
    right: 17px;
    top: 17px;
    height: 0.9em;
  }
}

.content {
  padding: 15px;

  display: flex;
  justify-content: center;
  text-align: center;
}

.takenContent {
  min-width: 192px;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
}

.priceTable {
  display: grid;
  grid-template-columns: 0fr 0fr 1fr;
  grid-gap: 15px;
  text-align: left;

  .headerCell {
    font-size: 20px;
    color: #627d98;
    text-align: left;
    display: none;

    &.registrar {
      margin-left: 10px;
    }
  }

  .registrarCell {
    height: 24px;
    line-height: 24px;

    &.cost {
      font-weight: 900;
      font-size: 30px;
      margin: 0 0.2em;
      font-family: "Source Code Pro", sans-serif;
    }

    &.register {
      color: #42e360;

      &.premium {
        // color: #f0b429;
      }
    }

    &.renew {
      color: #829ab1;
      text-decoration: line-through;
      font-weight: 800;
    }

    &.registar {
      color: #243b53;
      font-size: 22px;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .card {
    .titleBar {
      padding: 8px 80px;
      padding-bottom: 5px;
      font-size: 20px;

      .starContainer {
        top: 1px;
        right: -38px;

        .star {
          width: 25px;
        }
      }

      &.isTldInfoCard {
        padding: 8px 0;
        padding-bottom: 10px;
      }

      .infoIcon {
        position: absolute;
        right: 12px;
        top: 14px;
        height: 0.9em;
      }
    }
  }

  .priceTable {
    grid-gap: 10px;

    .headerCell {
      font-size: 16px;
    }

    .registrarCell {
      &.cost {
        font-size: 25px;
      }

      &.registar {
        font-size: 20px;
      }
    }
  }

  .takenContent {
    font-size: 14px;
  }
}
