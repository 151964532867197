.container {
  margin: 10px 5%;
}

.title {
  padding: 8px;
  margin: 0;
  font-size: 22px;
  font-weight: 800;
  color: #243b53;
}

.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(90px + 12px + 6px + 12px * 2));
  justify-content: left;
  padding-left: 36px;
}

.exploreText {
  padding-left: calc(
    36px + 12px
  ); // domain group left padding + first domain left margin
  margin-top: 20px;
  font-size: 18px;
}

@media screen and (max-width: 700px) {
  .container {
    margin: 10px 0;
  }

  .title {
    margin-left: 10px;
  }

  .items {
    margin-top: 0;
    padding-left: 0;
    grid-template-columns: repeat(auto-fill, calc(60px + 6px * 2 + 6px * 2));
    justify-content: space-evenly;
  }

  .exploreText {
    padding-left: 0;
    text-align: center;
  }
}

@media screen and (min-width: 1300px) {
  .container {
    margin: 15px 10%;
    padding-bottom: 15;

    .title {
      font-size: 25px;
      padding: 12px;
      margin-bottom: 5px;
    }

    .items {
      grid-template-columns: repeat(
        auto-fill,
        calc(120px + 12px + 6px + 12px * 2)
      );
    }

    .exploreText {
      font-size: 20px;
    }
  }
}
