.tutorial {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;

  &.active {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 12000px; // to be set dynamically to page height after scroll
    z-index: 100;
    background-color: #d9e2ec;
    opacity: 0.5;
  }

  .text {
    z-index: 200;
    margin-top: 15vh;
    width: 40vw;
    text-align: center;

    font-family: "Raleway", sans-serif;
    font-weight: 800;
    color: #102a43;
    font-size: 34px;
    text-shadow: 0px 0px 10px #829ab1;
  }

  .newsletterContainer {
    margin-top: 50px;
    z-index: 200;
    height: 300px;
    width: 350px;
  }
}

@media screen and (max-width: 850px) {
  .tutorial {
    .text {
      font-size: 22px;
      width: 90vw;
    }

    .newsletterContainer {
      height: 250px;
      width: 300px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .tutorial {
    .text {
      font-size: 28px;
    }
  }
}
