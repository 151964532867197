.container {
  width: 85%;
  max-width: 600px;
  margin: 50px auto 50px auto;
  // text-align: justify;
  font-size: 20px;
  line-height: 1.7;

  font-weight: 600;

  div {
    margin-top: 28px;
  }

  .tldsDomain {
    font-family: "Raleway", sans-serif;
    color: #42e360;
    font-weight: 800;
    font-size: 22px;
    margin-right: 3px;
  }

  .domain {
    margin: 0 5px 0 5px;
    font-family: "Raleway", sans-serif;
    color: #42e360;
    font-size: 22px;
    font-weight: 800;
    margin-right: 2px;
    display: inline-block;
  }

  .list {
    ul {
      // display: inline-block;
      vertical-align: top;
      margin: 0;
    }
  }

  .link {
    text-decoration: underline;
    text-decoration-color: #42e360;
    text-underline-offset: 0.1em;
  }

  .newsletterContainer {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
  .container {
    margin-top: 0;
    font-size: 15px;
  }

  .tldsDomain {
    font-size: 18px !important;
  }
  .domain {
    font-size: 18px !important;
  }

  .newsletterContainer {
    margin-top: 0;
  }
}

@media screen and (min-width: 1500px) {
  .container {
    margin-bottom: 100px;
  }
}
