.app {
  height: 100vh;
}

.spacer {
  height: calc(62px);
}

a {
  text-decoration: none;
  color: inherit;
}

.endText {
  margin: 60px auto 150px auto;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.tutorialBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 12000px; // to be set dynamically to page height after scroll
  z-index: 100;
  background-color: #d9e2ec;
  opacity: 0.5;
}
